import React, { useState, useEffect } from 'react';
import SearchGlass from '../asset/search-glass.svg'
import Trashcan from '../asset/trashcan.svg'
import InfoSign from '../asset/info-sign.svg'
import styled from 'styled-components';

const FetchList = ({ onDataLengthChange }) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  
  useEffect(() => {
    const randomData = [
      { id: '6', user: 'ben', status: 'red', provider: 'AWS', region: 'East US', size: 'Medium', cost: 1, name: 'cluster name'},
      { id: '5', user: 'ben', status: 'red', provider: 'GCP', region: 'East US', size: 'Medium', cost: 2, name: 'cluster name'},
      { id: '4', user: 'bob', status: 'green', provider: 'Azure', region: 'West US', size: 'Small', cost: 3, name: 'cluster name' },
      { id: '3', user: 'bob', status: 'orange', provider: 'AWS', region: 'Central US', size: 'Large', cost: 4, name: 'cluster name'},
      { id: '2', user: 'bill', status: 'green', provider: 'GCP', region: 'North Europe', size: 'Small', cost: 5, name: 'cluster' },
      { id: '1', user: 'bill', status: 'red', provider: 'Azure', region: 'Southeast Asia', size: 'Large', cost: 6, name: 'cluster name namename name '},
    ];
    setData(randomData);
  }, []);

  const header = [
    
    { id: 'status', title: 'Status' },
    { id: 'name', title: 'Name' },
    { id: 'provider', title: 'Provider' },
    { id: 'region', title: 'Region' },
    { id: 'size', title: 'Size' },
    { id: 'cost', title: 'Cost' }

  ];



  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  const filteredData = data.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === 'string' &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  
  const handleSortingChange = (field) => {
    setSortField(field);
    setSortOrder(
      sortField === field && sortOrder === "asc" ? "desc" : "asc"
    );
  };
  
  const sortedData = filteredData.sort((a, b) => {
    const fieldA = a[sortField];
    const fieldB = b[sortField];
  
    // convert numeric strings to numbers
    const valA = sortField === "cost" ? Number.parseFloat(fieldA) : fieldA;
    const valB = sortField === "cost" ? Number.parseFloat(fieldB) : fieldB;
  
    if (typeof valA === "number" && typeof valB === "number") {
      return sortOrder === "asc" ? valA - valB : valB - valA;
    }
    return sortOrder === "asc"
      ? valA.toString().localeCompare(valB.toString(), undefined, {
          numeric: true,
          sensitivity: "base",
        })
      : valB.toString().localeCompare(valA.toString(), undefined, {
          numeric: true,
          sensitivity: "base",
        });
  });
  
  const itemsPerPage = 5;
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  
  useEffect(() => {
    onDataLengthChange(data.length);
  }, [data, onDataLengthChange]);
  
  return (
    <StyledRegisterUser>
      <h2>Instances</h2>
      <input
        className="search-input"
        type="text"
        placeholder="Search"
        onChange={handleSearchChange}
      />
      <table className='table-class'>
        <thead>
          <tr>
            {header.map((item) => (
              <th className='sort-arrow' key={item.id} onClick={() => handleSortingChange(item.id)}>
                <div className='sort-container'>
                {item.title}
                {sortField === item.id && (
                  <div className='up-down-arrow'>{sortOrder === "asc" ? "▲" : "▼"}</div>
                )}
                
                  </div>
              
              </th>

              
            ))}
            <th className='action-th'>Action</th> 
          </tr>
        </thead>
        <tbody>
          {currentData.map((item) => (
            <tr key={item.id}>
              <td className='left-border'><StyledPtag status={item.status}></StyledPtag></td>
              <td>{item.name}</td>
              <td className='provider-td'>{item.provider}</td>
              <td >{item.region}</td>
              <td>{item.size}</td>
              <td>{item.cost}</td>
              {/* <td>{item.created}</td> */}
              {/* <td><FetchCost listUser={item.user} listId={item.id} listProvider={item.provider} sortDirection={sortDirection}/></td> */}
              <td className="options">
                <div className="dropdown ">
                  <div className='dropbtn'>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="dropdown-content">
                    <a href="#" className='more-info-btn'>More Info</a>
                    <div className='separator'></div>
                  <a href="#" className='delete-btn'>Delete</a>
                </div>
              </div>
            </td>
          </tr>
        ))}


      </tbody>
    </table>
    {data.length > 5 && (
<div className='pagination-container'>
  <button  className="prev-btn" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>{"<"}</button>
  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    <button 
      key={page} 
      onClick={() => setCurrentPage(page)} 
      className={page === currentPage ? 'active current-page' : ''}
    >
      {page}
    </button>
  ))}
  <button className="next-btn" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>{">"}</button>
</div>
)}
  </StyledRegisterUser>
  );
};


const StyledPtag = styled.div`
    background-color: ${p => {
    if (p.status === 'green') {
      return `green`;
    } else if (p.status=== 'orange') {
      return `orange`;
    } else if (p.status === 'red') {
      return `red`;
    }
  }};

  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
`


const StyledRegisterUser = styled.aside`
width: 70%;
margin: auto;
font-size:1em ;
&:focus{
  outline:none;   
}

/* DROPDOWN BUTTON START*/

.dropbtn > div{
  background-color: gray;
  width: 0.7rem;
  margin-bottom: 0.3rem;
  border-radius: 50%;
  height: 0.7rem;
}

.dropbtn {
    height: 2.5rem;
    width: 3rem;
    cursor: pointer;
    &:hover > div{
      transition: 0.2s;
    background-color: black;
    }
  }

/* DROPDOWN BUTTON END */

/* SEARCH START */
.search-input{
  height: 2.5rem;
  padding-left: 2.5rem;
  box-sizing: border-box;
  width: 40%;
  background-image: url(${SearchGlass});
  background-size: 1.563rem 1.563rem;
  background-position: 0.4rem;
  background-repeat: no-repeat;
  border-radius: 10px;
  border: 1px solid black;
}


.sort-container{
  display: flex;
  justify-content: center;
}

/* SEARCH END */

/* PAGINATION START */
.pagination-container > .next-btn, .pagination-container > .prev-btn{
  background-size: contain;
}

.pagination-container > button{
  padding: 0.6rem;
  border: none;
  font-size: 1.05rem;
  width: 2rem;
  height: 2rem;
}

.current-page{
  background-color: rgb(229, 248, 229);
  border: none;
  color: green;
}

/* PAGINATION END */

/* DROPDOWN CONTAINER START */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    /* right: 0; */
    border-radius: 10px;
    background-color: white;
    border: 1px solid black;
    min-width: 8.5rem;
    min-height: 6.6rem;
    padding: 0.3rem;
    box-sizing: border-box;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-content a {
    padding: 0.5rem;
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .dropdown-content a:hover {
    text-decoration: underline;
  }
  

  .separator{
    background-color: black;
    height: 0.05rem;
    width: 6rem;
    margin: auto;
  }
  
  .more-info-btn::before, .delete-btn::before{
    content: '';
    background-repeat: no-repeat;
    background-size: cover;
    width: 1.8rem;
    margin-right: 0.6rem;
    height: 1.8rem;
  }

.more-info-btn::before{
background-image: url(${InfoSign});
  }

 .delete-btn::before{
 background-image: url(${Trashcan});
 }

  .left-border{
    border-left: 1px solid #ddd;
  }
  

.up-down-arrow{
  margin-left: 0.5rem;
}

/* DROPDOWN CONTAINER END */


/* TABLE CSS STARTS HERE */

  table {
    width: 100%;
  }

  .options{
    border-right: 1px solid #ddd;
  }

  td{
    background-color: white;
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;
  }
  th, td {
    /* padding: 0.5rem; */
    /* background-color: red; */
    /* margin: 1rem; */
  }
  tr{
    width: 100%;
    display: table;
    table-layout: fixed;
    border-spacing: 0 0.4rem;
  }
  table td div{
    margin: auto;
  }

  tr td{
     word-break: break-word;
  hyphens: auto;
    
  }
  th div:hover{
    cursor: pointer;
  }

  @media only screen and (max-width: 650px) {
    width: 98%;
    font-size:0.8em ;

    .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    z-index: 1;
    border-radius: 10px;
    background-color: white;
    border: 1px solid black;
    min-width: 8.5rem;
    min-height: 6.6rem;
    padding: 0.3rem;
    box-sizing: border-box;
  }
  
}
  /* TABLE CSS ENDS HERE */

`

export default FetchList;
