import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logout from '../component/logoutBtn';
import auth from "../firebaseConfig.js";
const Settings = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [email, setEmail] = useState('');

    const handleDropdownClick = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (user) {
          setEmail(user.email);
        } else {
          setEmail(null);
        }
      });
      return () => unsubscribe();
    }, []);

 
  return (
    <StyledSettingsWrap>
    <StyledSettings id='settings'>
    <section className='top-container-setting'>
    <img></img>

    {/* <p >{email}</p> */}
   
   <div className='setting-separator'></div>


    </section>
    <ul className="ul-container">
    <li><Link to="/profile">Profile</Link></li>
    <li className='setting'>
      <a onClick={handleDropdownClick}>Settings</a>
      {isDropdownOpen && (
        <ul className="dropdown">
          <li><Link to="/changePassword">Change password</Link></li>
          <li><Link to="/changeEmail">Change Email</Link></li>
        </ul>
      )}
    </li>
    <li><Logout /></li>
  </ul>
  </StyledSettings>
  </StyledSettingsWrap>
  );
};


const StyledSettings = styled.article`

background-color: white;
  position: absolute;
  /* right: 10vmin; */
  top: 0rem;
  /* justify-self: flex-end; */
  width: 17rem;
  /* background-color: red; */
  border: 1px solid rgba(0,0,0,0.3);
  margin: auto;
  border-radius: 5px;

  .top-container-setting > p{
    color: black;
    font-size: 1.05rem;
  }

*{
  margin: 0;
  padding: 0;
}
.setting-separator{
  display: none;
}
.top-container-setting{
  padding: 1rem 1rem 0 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border-bottom: 1px solid black; */
  background-color: transparent;
  width: 90%;
  margin: auto;
} 




img{
  border-radius: 50%;
  background-color: #2C2C2C;
  min-width: 3rem;
  min-height: 3rem;
}
.ul-container{
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
width: 100%;
}

.dropdown{
  /* background-color: green; */
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

li{
  min-height: 3rem;
  width: 100%;
  border-top: 1px solid rgba(0,0,0,0.3);
  border-bottom: 0px;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown li{
  min-height: 3rem;
  width: 17rem;
  /* border-top: 1px solid rgba(0,0,0,0.3); */
  border-bottom: 0px;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dropdown li:last-child{
margin-bottom: -2rem;
}

/* li:nth-of-type(1){
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
} */

.setting{
  max-height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
box-sizing: border-box;
}

.ul-container > li a{
  font-size: 1.2rem;
  /* font-weight: 500; */
  opacity: 1;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
}


/* .ul-container > li button, .ul-container > li a{
  color: black !important;
  font-size: 1rem;
  font-weight: 400;
} */


@media only screen and (max-width: 950px) {
  width: 14rem;
  .dropdown li{
  width: 14rem;
}
}

`
const StyledSettingsWrap = styled.article`

.ul-container > li button, .ul-container > li a{
  color: black ;
  font-size: 1rem;
  font-weight: 400;
}
@media only screen and (max-width: 950px) {
overflow: hidden;

.ul-container, .ul-container li, li, .top-container-setting, .dropdown{
  padding: 0;
  margin: 0;
}

  ul li, .dropdown li{
    justify-content: center;
    align-items: flex-start;
  }

  .ul-container li{
  min-height: 2rem ;
}


.top-container-setting, li{
  border: none;
}

.dropdown{
 height: 4rem;
 justify-content: flex-start;
}

.dropdown li{
  height: 2rem;
  border-bottom: 0px;
}
.setting-separator{
  display: block;
  /* margin: auto; */
  width: 100%;
  height: 0.1rem;
  background-color: gray;
  margin-bottom: 0.5rem;
  /* background-color: red; */
}

  .ul-container > li button, .ul-container > li a{
  font-size: 0.875rem;
  padding-left: 0.5rem;
}
  .setting, .setting a{
    align-items: flex-start;
  }

 .setting > a{
  height: 2rem;
  display: flex;
    align-items: flex-start;

    justify-content: center;
    align-items: center;
    /* background-color: yellow; */
  }

  #settings{
  position: static;
  border: none;
}
.top-container-setting img{
  display: none;
}

}
`
export default Settings;
