import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { getDatabase, ref, set } from 'firebase/database';
import { createClient } from 'contentful-management';

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID
const PAT = process.env.REACT_APP_PAT

const CreateUser = ({ auth }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleCreateUser = async () => {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      console.log('User created successfully:', user.uid);

      // Send the user data to Firebase database
      const database = getDatabase();
      await set(ref(database, 'users/' + user.uid), {
        email: email,
        password: password,
        // uid: user.uid,
      }).catch((error) => {
        console.error(error);
      });

   
      const client = createClient({
        accessToken: PAT,
      });
      const space = await client.getSpace(SPACE_ID);
      // Make testPost and master .env when making the final contentful content models
      const environment = await space.getEnvironment('master');
      const entry = await environment.createEntry('testPost', {
        fields: {
          title: {
            'en-US': {
              value: 'New blog post',
              type: 'Text'
            }
          },
          body: {
            'en-US': {
              value: 'Body of the blog post',
              type: 'Text'
            }
          },
          author: {
            'en-US': user.uid,
          }
        }
      });
      await entry.publish();
      console.log('Entry created successfully:', entry);

      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyledRegisterUser>
      <h3>Register</h3>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleCreateUser}>Register</button>
      {/* <button >Back</button> */}
      <a onClick={(e) => navigate('/')}> Back</a>
    </StyledRegisterUser>
  );
};

const StyledRegisterUser = styled.aside`
margin: auto;
width: 50%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;

form div{
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;
}

form div a{
  display: flex;
  align-items: flex-start;
}

h3{
  margin-top: 7rem;
}

form{
  display: flex;
  flex-direction: column;
}
input{
  border-radius: 5px;
  background-color: white;
  padding-left: 2rem;
  margin-top: 1.5rem;
  width: 27rem;
  height: 2.5rem;
  /* border: 1px solid #2C2C2C; */
  border: 1px solid rgba(0, 0, 0, .1);
  outline: none;
}
 button{
  border-radius: 5px;
  border: none;
  background-color: black;
  margin-top: 1.5rem;
  width: 29.5rem;
  height: 2.5rem;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
  margin-bottom: 5em;
}

a{
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

button:nth-of-type(1){
  margin-top: 3rem;
}

section{
  display: flex;
}
`

export default CreateUser;