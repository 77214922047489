// import React, { useState } from 'react';

// const AwsForm = (props) => {
//   const [region, setRegion] = useState('eu-west-1');
//   const [size, setSize] = useState('t3.medium');

//   const handleRegionChange = (e) => {
//     setRegion(e.target.value);
//     props.onRegionChange(e.target.value);
//   }

//   const handleSizeChange = (e) => {
//     setSize(e.target.value);
//     props.onSizeChange(e.target.value);
//   }

//   return (
//     <div>
//       <form >
//         <label htmlFor="region">
//           Region:
//           <select id="region" value={region} onChange={handleRegionChange}>
//             <option value="eu-west-1">eu-west-1</option>
//             <option value="eu-north-1">eu-north-1</option>
//             <option value="eu-west-2">eu-west-2</option>
//           </select>
//         </label>
//         <br />
//         <label htmlFor="size">
//           Size:
//           <select id="size" value={size} onChange={handleSizeChange}>
//             <option value="t3.small">t3.small</option>
//             <option value="t3.medium">t3.medium</option>
//             <option value="t3.large">t3.large</option>
//           </select>
//         </label>

//       </form>
//       <br />
//       <div>
//     </div>
//     </div>
//   );
// };


// export default AwsForm;

import React, { useState } from 'react';
import styled from 'styled-components';
import RegionButton from '../component/regionButton';
import SizeButton from '../component/sizeButton';
import SwedishFlag from '../asset/sweden-flag.svg'
import UkFlag from '../asset/uk-flag.svg'
import IrlandFlag from '../asset/irland-flag.svg'
import LargeSize from '../asset/large-size.svg'
import MediumSize from '../asset/medium-size.svg'
import SmallSize from '../asset/small-size.svg'

const GcpForm = (props) => {
  const [region, setRegion] = useState('eu-west-1');
  const [size, setSize] = useState('t3.medium');

  const handleAwsRegionChange = (selectedRegion) => {
    setRegion(selectedRegion);
    props.onAwsRegionChange(selectedRegion);
  };

  const handleAwsSizeChange = (selectedSize) => {
    setSize(selectedSize);
    props.onAwsSizeChange(selectedSize);
  };
//           <select id="region" value={region} onChange={handleRegionChange}>
//             <option value="eu-west-1">eu-west-1</option>
//             <option value="eu-north-1">eu-north-1</option>
//             <option value="eu-west-2">eu-west-2</option>
//           </select>
  return (
    <StyledAzureForm>
      
      <h3>Choose Region</h3>
      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of </p>
      <form>

          <RegionButton
            region="eu-west-1"
            selected={region === 'eu-west-1'}
            country="Ireland"
            flag={IrlandFlag}
            onClick={() => handleAwsRegionChange('eu-west-1')}
          />
          <RegionButton
            region="eu-north-1"
            selected={region === 'eu-north-1'}
            country="Stockholm"
            flag={SwedishFlag}
            onClick={() => handleAwsRegionChange('eu-north-1')}
          />
          <RegionButton
            region="eu-west-2"
            selected={region === 'eu-west-2'}
            country="London"
            flag={UkFlag}
            onClick={() => handleAwsRegionChange('eu-west-2')}
          />

      </form>
     
      <h3>Select Size</h3>
      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of </p>
      <form>
        
          <SizeButton
            size="t3.small"
            sizeIcon={SmallSize}
            selected={size === 't3.small'}
            monthlyCost="€10/month"
            hourlyCost="€0.004/hour"
            onClick={() => handleAwsSizeChange('t3.small')}
          />
          <SizeButton
            size="t3.medium"
            sizeIcon={MediumSize}
            selected={size === 't3.medium'}
            monthlyCost="€10/month"
            hourlyCost="€0.004/hour"
            onClick={() => handleAwsSizeChange('t3.medium')}
          />
          <SizeButton
            size="t3.large"
            sizeIcon={LargeSize}
            selected={size === 't3.large'}
            monthlyCost="€10/month"
            hourlyCost="€0.004/hour"
            onClick={() => handleAwsSizeChange('t3.large')}
          />

      </form>
    </StyledAzureForm>
  );
};

const StyledAzureForm = styled.div`
  form {
    display: flex;
    justify-content: space-between;
  }
  div:nth-child(2){
  /* background-color: red; */
  margin-left: 1rem;
  margin-right: 1rem;
  box-sizing: border-box;
}
  h3{
  margin-top: 4rem;
}

@media only screen and (max-width: 900px) {
  div:nth-child(2){
  /* background-color: red; */
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  box-sizing: border-box;
}
}
`;

export default GcpForm;

