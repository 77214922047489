import React, { useState } from 'react';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NavBar from '../component/navbar';

const ChangePassword = ({auth}) => {
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const user = auth.currentUser;
      const credentials = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credentials);
      await updatePassword(user, newPassword);
      console.log('Password updated successfully!');
      setMessage('Email updated successfully!')
    } catch (error) {
      setError(error.message);
      setMessage('Incorrect password or email!')
    }
  };

  return (
    <StyledChangePassword>
      <NavBar/>
      {message && <p className="error">{message}</p>}
      <form >
      <h2>Change your password</h2>
        <label htmlFor="new-password">New Password</label>
        <input
          id="new-password"
          type="password"
          value={newPassword}
          placeholder="New password"
          onChange={(event) => setNewPassword(event.target.value)}
          required
        />

        <label htmlFor="current-password">Current Password</label>
        <input
          id="current-password"
          type="password"
          placeholder='Current password'
          value={currentPassword}
          onChange={(event) => setCurrentPassword(event.target.value)}
          required
        />

        

        <div className='btn-container'>
        <button className='back-btn'>Back</button>
        <button className='change-email-btn' onClick={handleSubmit}>Change Password</button>
        </div>
      </form>
    </StyledChangePassword>
  );
};


const StyledChangePassword = styled.main`
margin-top: 5rem;
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
p{
  font-size: 1.4rem;
}
form{
  margin: auto;
  display: flex;
  flex-direction: column;
}
input{
  width: 23rem;
  height: 2.5rem;
  padding-left: 0.7rem;
  box-sizing: border-box;
}

label{
  margin: 1rem 0 0.5rem 0;
}

.btn-container button{
  width: 7rem;
  height: 2.5rem;
  border: none;
  border-radius: 10px;
  &:hover{
  cursor: pointer;
  text-decoration: underline;
  }
}

.btn-container{
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.btn-container .change-email-btn{
  color: black;
  background-color: #1ae540;
  width: 8rem;
}

.back-btn{
  background-color: black;
  color: white;
}

`

export default ChangePassword;
