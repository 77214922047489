// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// // const AKS_API_ENDPOINT = 'https://management.azure.com/subscriptions/2637242c-f448-40be-aa44-37154d829d0e/providers/Microsoft.ContainerService/managedClusters?api-version=2022-09-01';
// // const TOKEN = 'FWc8Q~NE0pASVaJ0DdiMVsWdBFwDflFZ4pXwJdv3';

// const AzureList = () => {
//   const [data, setData] = useState([]);
// // console.log("PROCESS TOKEN", process.env.REACT_APP_AZURE_TOKEN)
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const result = await axios.get('https://management.azure.com/subscriptions/2637242c-f448-40be-aa44-37154d829d0e/providers/Microsoft.ContainerService/managedClusters?api-version=2022-09-01', {
//           headers: {
//             Authorization: `Bearer ${process.env.REACT_APP_AZURE_TOKEN}`,
//           },
//         });
//         console.log("This is the data", data)
//         setData(result.data);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       <h2>List of Azure Clusters:</h2>
//       {/* {data.map(item => (
//   <div key={item.id}>{item.id}</div>
// ))} */}
//     </div>
//   );
// };

// export default AzureList;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const AzureList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const randomData = [
      { id: 1, name: 'Cluster 1', location: 'East US', property1: 'value1', property2: 'value2' },
      { id: 2, name: 'Cluster 2', location: 'West US', property1: 'value1', property2: 'value2' },
      { id: 3, name: 'Cluster 3', location: 'Central US', property1: 'value1', property2: 'value2' },
      { id: 4, name: 'Cluster 4', location: 'North Europe', property1: 'value1', property2: 'value2' },
      { id: 5, name: 'Cluster 5', location: 'Southeast Asia', property1: 'value1', property2: 'value2' },
    ];
    setData(randomData);
  }, []);

  return (
    <StyledRegisterUser >
      <h2>List of Azure Clusters:</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Location</th>
            <th>Property 1</th>
            <th>Property 2</th>
          </tr>
        </thead>
        <tbody>
  {data.map(item => (
    <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.location}</td>
      <td>{item.property1}</td>
      <td>{item.property2}</td>
      <td>
        <div className="dropdown">
          <button className="dropbtn">Options</button>
          <div className="dropdown-content">
            <a href="#">More Info</a>
            <a href="#">Delete</a>
          </div>
        </div>
      </td>
    </tr>
  ))}
</tbody>
      </table>
    </StyledRegisterUser>
  );
};

const StyledRegisterUser = styled.aside`
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-content a {
    display: block;
    padding: 8px;
    text-decoration: none;
    color: black;
    background-color: #f1f1f1; 
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 8px;
    font-size: 14px;
    border: none;
    cursor: pointer;
  }
`

export default AzureList;