import styled from "styled-components";

const SizeButton = ({ size, selected, onClick, monthlyCost, hourlyCost, sizeIcon }) => {
    return (
      <StyledOption
        className={`option ${selected ? 'selected' : ''}`}
        onClick={onClick}
      >
        <div className="top-wrap">
        {/* <div className="img-div"></div> */}
        <div className="img-div" style={{ backgroundImage: `url(${sizeIcon})` }}></div>
        {size}
        </div>
        <div className="line-break"></div>
        <p>
        {monthlyCost}
        </p>
        <p>
        {hourlyCost}
        </p>

      </StyledOption>
    );
  };

  const StyledOption = styled.div`
  
    cursor: pointer;
    margin-top: 2rem;
    min-width: 18vmin;
  width: 24vmin;
  min-height: 15vmin;
  min-width: 18vmin;

  /* min-height: 15.25vmin; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: white;
 color: black;
  box-sizing: border-box;
  flex-direction: column;
  /* margin-bottom: 2rem; */
  :hover{
    transition: 0.3s;
    box-shadow: 0px 0px 10px 0px rgba(255,255,255,0.75);

  }
  .top-wrap{
    padding: 1rem 1rem 0 1rem;
    box-sizing:border-box;
    width: 100%;
    
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 1.5rem;
  }
  //The important is to prevent the parent css to take over, instead of adding more lines of code around it
 p{
  color:black !important;
  margin: 0;
 }
 p:nth-of-type(1){
font-weight: bold;
 }
 p:nth-of-type(2){
  opacity: 0.85;
  font-size: 0.8rem;
 }
  .img-div{
    margin-right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

  }
  .line-break{
    background-color: black;
    width: 100%;
    height: 1px;
    margin-bottom: 0.4rem;
  }
    &.selected {
      position: relative;
      border: 1px solid #00FF38;
      caret-color: transparent;
      overflow: hidden;
      &:after{
        content: "";
        top: -1.5rem;
        right: -1.5rem;
        border-top-right-radius: 10px;
        rotate: 50deg;
        position: absolute;
        width: 45px;
        height: 45px;
        background-color: #00FF38;
      }
    }

    @media only screen and (max-width: 900px) {
    .top-wrap{
    flex-direction: column;
    font-size: 2.5vw;
  }

  p:nth-of-type(2){
  padding-bottom: 0.5rem;
 }
  
  .img-div{
    margin-right: 0rem;
  }
}

@media only screen and (max-width: 670px) {
    .top-wrap{
    font-size: 3vw;
  }
}
@media only screen and (max-width: 420px) {
    .top-wrap{
    font-size: 3.9vw;
  }
}
  `;

  export default SizeButton