import React, { useState, useEffect } from 'react';
import Logout from '../component/logoutBtn';
import AwsList from '../dashboard/awsList';
import AzureList from '../dashboard/azureList';
import GcpList from '../dashboard/gcpList';
import { useNavigate } from 'react-router-dom';
import { dataApi } from '../api/api';
import AwsForm from '../dashboard/awsForm';
import GcpForm from '../dashboard/gcpForm';
import AzureForm from '../dashboard/azureForm';
import styled from 'styled-components';
import PurchaseList from '../dashboard/purchaseList';
import AWSIcon from '../asset/aws-icon.svg'
import AzureIcon from '../asset/azure-icon.svg'
import GCPIcon from '../asset/gcp-icon.svg'
import NavBar from '../component/navbar';
import Checkout from './checkout';



const CreateCluster = ({auth}) => {
  const [region, setRegion] = useState('eu-west-1');
  const [size, setSize] = useState('t3.medium');
  const [provider, setProvider] = useState('aws');
  const [page, setPage] = useState(1);
  const [clusterName, setClusterName] = useState('');
  const [selectedVersion, setSelectedVersion] = useState('1.25');

  const navigate = useNavigate();


  const choosePage = (page) => {
    setPage(page);
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        console.log('User is already signed in from dashboard:', user.email);
      } else {
        console.log('User is not signed in from dashboard');
        navigate("/")
      }
    });
    return () => unsubscribe();
  }, []);

console.log("OVER HERE", page)
  useEffect(() => {
      if (provider === 'gcp') {
        setRegion("europe-north1");
        setSize("e2-standard-4");
      } else if (provider === 'aws') {
        setRegion("eu-west-1");
        setSize("t3.medium");
      } else if (provider === 'azure') {
        setRegion("westeurope");
        setSize("standard_D2S_v3");
      }
    
  }, [provider]);


//   useEffect(() => {
// if(page===2){
//   navigate("/checkout", { state: page })
// }
//   }, [page]);


  const handleAwsRegionChange = (regionValue) => {
    setRegion(regionValue);
  }
  const handleAwsSizeChange = (sizeValue) => {
    setSize(sizeValue);
  }

  const handleGcpRegionChange = (gcpRegionValue) => {
    setRegion(gcpRegionValue);
  }
  const handleGcpSizeChange = (gcpSizeValue) => {
    setSize(gcpSizeValue);
  }

  const handleAzureRegionChange = (AzureRegionValue) => {
    setRegion(AzureRegionValue);
  }
  const handleAzureSizeChange = (AzureSizeValue) => {
    setSize(AzureSizeValue);
  }
  

  const handleDeleteAxios = async (e) => {
    e.preventDefault();
    var response = await dataApi.deleteCluster(provider,region,size);
    if (response.status !== 204) {
      throw new Error(response.data);
    }
  };
  // const [provider, setProvider] = useState("");

  const handleClick = (value) => {
    setProvider(value);
  };
  function handleInputChange(event) {
    setClusterName(event.target.value);
  }

  const handleVersionChange = (event) => {
    setSelectedVersion(event.target.value);
  };

  console.log("THIS IS SELECTED VERSION", selectedVersion)

  return (
<StyledCreateInstance >
<NavBar/>
<StyledCreateInstance2 >


  <h2>Create new instances</h2>

  <h3>Choose cluster name</h3>
  <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of </p>

  <input
        type="text"
        id="clusterNameInput"
        value={clusterName}
        onChange={handleInputChange}
        placeholder='Cluster name'
      />

<h3>Select version</h3>
<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of </p>

      <label htmlFor="version-select">Select prefered cluster version</label>
      <select id="version-select" value={selectedVersion} onChange={handleVersionChange}>
        <option>
          1.25
        </option>
        <option>
          1.24
        </option>
        <option>
          1.23
        </option>
        <option>
          1.22
        </option>
      </select>

  <h3>Choose provider</h3>
  <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of </p>

  
  
 
  <StyledSection>
    
      <StyledDiv 
      provider={provider}
      selectedProvider="aws" onClick={() => handleClick("aws")}
      className={provider === "aws" ? "selected" : "aws"}>
        <div></div>
        AWS
      </StyledDiv>
      <StyledDiv 
      provider={provider} 
      selectedProvider="azure" onClick={() => handleClick("azure")}
      className={provider === "azure" ? "selected" : "azure"}>
      <div></div>
        Azure
      </StyledDiv>
      <StyledDiv 
      provider={provider} 
      selectedProvider="gcp" onClick={() => handleClick("gcp")}
      className={provider === "gcp" ? "selected" : "gcp"}>
      <div></div>
        GCP
      </StyledDiv>
    </StyledSection>

    {provider === 'gcp' && (
      <>
        <GcpForm onGcpRegionChange={handleGcpRegionChange} onGcpSizeChange={handleGcpSizeChange} />
      </>
    )}
    {provider === 'aws' && (
      <>
        <AwsForm onAwsRegionChange={handleAwsRegionChange} onAwsSizeChange={handleAwsSizeChange} />
      </>
    )}
    {provider === 'azure' && (
      <>
        <AzureForm onAzureRegionChange={handleAzureRegionChange} onAzureSizeChange={handleAzureSizeChange} />
      </>
    )}

    <br/>
    <PurchaseList provider={provider} region={region} size={size} clusterName={clusterName} selectedVersion={selectedVersion} choosePage={choosePage}/>
  </StyledCreateInstance2>
  </StyledCreateInstance>
  );

  
};

const StyledSection = styled.section`
  display: flex;
  justify-content: space-between;

div:nth-child(2){
  margin-left: 1rem;
  margin-right: 1rem;
  box-sizing: border-box;
}

@media only screen and (max-width: 900px) {
  div:nth-child(2){
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  box-sizing: border-box;
}
}
`;

const StyledDiv = styled.div`
margin-top: 2rem;
  background-color: white;
  cursor: pointer;
  min-width: 18vmin;
  width: 24vmin;
  height: 13vmin;
  /* border: 1px solid black; */
  text-overflow: clip;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 1vmin 2vmin;
  box-sizing: border-box;
  justify-content: center;
  /* flex-direction: column; */

  .azure{
    background-color: red;
    width: 10rem;
    height: 10rem;
  }

  :hover{
    transition: 0.3s;
    box-shadow: 0px 0px 10px 0px rgba(255,255,255,0.75);

  }
  div{
    min-height: 6vmin;
    min-width: 6vmin;
    /* border: 1px solid black; */
    margin-right: 2vmin;
    background-image: ${props => {
    if (props.selectedProvider === 'aws') {
      return `url(${AWSIcon})`;
    } else if (props.selectedProvider === 'azure') {
      return `url(${AzureIcon})`;
    } else if (props.selectedProvider === 'gcp') {
      return `url(${GCPIcon})`;
    }
  }};
  background-size: contain;
  background-repeat: no-repeat;
  }

  &.selected {
    position: relative;
      caret-color: transparent;
      overflow: hidden;
      &:after{
        content: "";
        top: -1.5rem;
        right: -1.5rem;
        border-top-right-radius: 10px;
        rotate: 50deg;
        position: absolute;
        width: 45px;
        height: 45px;
        background-color: #00FF38;
      }
  }

  @media only screen and (max-width: 950px) {
    
  flex-direction: column;

  div {
    margin:  auto;
  }
}

  @media only screen and (max-width: 670px) {
  min-width: 18vmin;
  width: 24vmin;
  margin: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  div {
    min-height: 2.5rem;
    min-width: 2.5rem;
    margin: 0.5rem auto;
  }
}
`;


const StyledCreateInstance2 = styled.aside`

display: flex;
flex-direction: column;
text-align: center;
background-color: black;
margin: auto;
min-width: 60vmin;
width: 55vw;
padding: 2rem;
border-radius: 20px;
box-sizing: content-box;
padding-top: 2rem;

#clusterNameInput{
  padding-left: 1rem;
  box-sizing: border-box;
height: 2rem;
border: none;
border-radius: 5px;
width: 50%;
margin: auto;
margin-bottom: 2rem;
}

#version-select{
 border: none;
 border-radius: 2px;
  padding-left: 1rem;
  height: 1.5rem;
  width: 30%;
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}


h2, h3, p, >label{
  color: white;
}
section{
  display: flex;
}

/* 861 x 1424 look into this style for laptop */
@media only screen and (max-width: 1550px) {
  width: 60%;
}
@media only screen and (max-width: 1400px) {
  width: 65%;
}
@media only screen and (max-width: 1300px) {
  width: 70%;
}
@media only screen and (max-width: 1200px) {
  width: 75%;
}
@media only screen and (max-width: 1100px) {
  width: 80%;
}
@media only screen and (max-width: 1000px) {
  width: 85%;
}
@media only screen and (max-width: 900px) {
  width: 80%;
}
`

const StyledCreateInstance = styled.aside`

width: 100%;

h2, h3, p{
  color: white;
}
section{
  display: flex;
}

@media only screen and (max-width: 900px) {
  /* width: 80%; */
}
`


export default CreateCluster;



