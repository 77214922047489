import React, { useState } from 'react';
import { reauthenticateWithCredential, EmailAuthProvider, updateEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NavBar from '../component/navbar';


const ChangeEmail = ({auth}) => {
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const user = auth.currentUser;
      const credentials = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credentials);
      await updateEmail(user, newEmail);
      console.log('Email updated successfully!');
      setMessage('Email updated successfully!')
      
    } catch (error) {
      setError(error.message);
      setMessage('Incorrect password or email!')
    }
  };

  return (
    <StyledChangeEmail>
      <NavBar/>
      <form >
        <h2>Change your email</h2>
      {message && <p className="error">{message}</p>}
        <label htmlFor="new-email">New Email</label>
        <input
          id="new-email"
          type="email"
          placeholder='Email'
          value={newEmail}
          onChange={(event) => setNewEmail(event.target.value)}
          required
        />

        <label htmlFor="password">Current Password</label>
        <input
          id="password"
          type="password"
          value={password}
          placeholder="Password"
          onChange={(event) => setPassword(event.target.value)}
          required
        />

        
        <div className='btn-container'>
        <button className='back-btn'>Back</button>
        <button className='change-email-btn' onClick={handleSubmit}>Change Email</button>
        </div>
      
      </form>
    </StyledChangeEmail>
  );
};


const StyledChangeEmail = styled.main`
margin-top: 5rem;
display: flex;
flex-direction: column;
width: 100%;
/* background-color: red; */
p{
  font-size: 1.4rem;
}
form{
  margin: auto;
  display: flex;
  flex-direction: column;
}
input{
  width: 23rem;
  height: 2.5rem;
  padding-left: 0.7rem;
  box-sizing: border-box;
}

label{
  margin: 1rem 0 0.5rem 0;
}

.btn-container button{
  width: 7rem;
  height: 2.5rem;
  border: none;
  border-radius: 10px;
  &:hover{
  cursor: pointer;
  text-decoration: underline;
  }
}

.btn-container{
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.btn-container .change-email-btn{
  background-color: #1ae540;
  width: 8rem;
  color: black;
}

.back-btn{
  background-color: black;
  color: white;
}

`

export default ChangeEmail;
