import React, { useState } from 'react';

import styled from 'styled-components';

const LandingPage = ({auth}) => {


  return (
    <StyledLandingPage>
   <p>Hello</p>
    </StyledLandingPage>
  );
};


const StyledLandingPage = styled.main`


`

export default LandingPage;
