import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from "firebase/auth";

const ResetPassword = ({ auth }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setMessage(error.message);
    }
  };

  console.log(auth); 

  return (
    <StyledResetPassword>
      <h3>Reset Password</h3>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleResetPassword}>Reset Password</button>
      <a href='#' onClick={(e) => navigate('/')}> Back</a>
      {message && <p>{message}</p>}
    </StyledResetPassword>
  );
};


const StyledResetPassword = styled.main`
margin: auto;
width: 50%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
margin-top: 7rem;
a{
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: black;
}

button{
  border-radius: 5px;
  border: none;
  background-color: black;
  margin-top: 1.5rem;
  width: 29.5rem;
  height: 2.5rem;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
  margin-bottom: 5em;
}

input{
  border-radius: 5px;
  padding-left: 2rem;
  margin-top: 1.5rem;
  width: 27rem;
  height: 2.5rem;
  border: 1px solid rgba(0, 0, 0, .1);
  outline: none;
}
`
export default ResetPassword;
