import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {useNavigate } from 'react-router-dom';
import ChangeEmail from '../settings/changeEmail';
import ChangePassword from '../settings/changePassword';
import Settings from '../settings/settings';
import NavBar from '../component/navbar';



const Profile = ({auth}) => {

  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);

  console.log("Current user ", auth)


  return (
    <StyledProfile >
<NavBar/>
  <div className='img'></div>
        
  <p>{auth.currentUser.email}</p>
  

  <h3>Contact information</h3>

<div className='name container'>
<p>Name</p>
<p>John Doe</p>
</div>

<div className='email container'>
<p>Email</p>
<p>{auth.currentUser.email}</p>
</div>

    </StyledProfile>
  );
};

const StyledProfile = styled.aside`
width: 100%;
display: flex;
/* justify-content: center; */
flex-direction: column;
align-items: center;
/* background-color: green; */
.img{
  margin: 1rem 0;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-color: #2C2C2C;

}

.container{
  display: flex;
  width: 20rem;
  /* background-color: yellow; */
  justify-content: space-between;
}

.container p:last-child{
opacity: 0.5;
}
`


export default Profile



