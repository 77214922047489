import React, { useState, useEffect } from 'react';
import AwsList from '../dashboard/awsList';
import AzureList from '../dashboard/azureList';
import { dataApi } from '../api/api';
import AwsForm from '../dashboard/awsForm';
import GcpForm from '../dashboard/gcpForm';
import AzureForm from '../dashboard/azureForm';
import styled from 'styled-components';
import PurchaseList from '../dashboard/purchaseList';
import CreateCluster from './createCluster';
import {useNavigate } from 'react-router-dom';
import NavBar from '../component/navbar';
import { createClient } from 'contentful';
import FetchList from '../dashboard/fetchList';

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
});

const Dashboard = ({auth}) => {

  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [listLength, setListLength] = useState(0);
  // const [test, setTest] = useState('');

  const handleDataLengthChange = (length) => {
    // Do something with the length of data
    console.log('Data length:', length);
    setListLength(length)
  };

  // console.log("This is from the console log list length", listLength)
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        console.log('User is already signed in from dashboard:', user.email);
        console.log('UID', user.uid);
        console.log('USERID', user.userId);
        setUser(user);
        fetchUserData(user.uid);
      } else {
        console.log('User is not signed in from dashboard');
        setUser(null);
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchUserData = async (userId) => {
    try {
      const response = await client.getEntries({
        content_type: 'testPost',
        'fields.author': userId,
      });
      if (response.items && response.items.length > 0) {
        setUserId(response.items[0].fields.author);
      } else {
        console.log('No items found for user ID:', userId);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <StyledCreateInstance >
      <NavBar/>
      {listLength <= 0 && 
            <div className='container'>
            <div></div>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover </p>
            <button className='create-btn' onClick={() => navigate("/createCluster")} >+ Create cluster</button>
          </div>
      }

       <FetchList onDataLengthChange={handleDataLengthChange} />
       {listLength >= 1 && 
            <button className='create-btn' onClick={() => navigate("/createCluster")} >+ Create cluster</button>
      }
      <br/>
    </StyledCreateInstance>
  );
};

const StyledCreateInstance = styled.aside`
margin: auto;
/* width: 100%; */
text-align: center;

h3{
  font-size: 1.8rem;
}

.container{
  margin: auto;
  border: 1px solid black;
  padding: 2rem 2rem 1rem 2rem;
  width: 50%;
}

.container div{
  width: 100%;
  height: 10rem;
  background-color: #D9D9D9;
}
.create-btn{
  border: none;
  width: 16rem;
  margin-top: 1rem;
  height: 3.5rem;
  background-color: #00FF38;
  border-radius: 15px;
  font-size: 1.3rem;
  &:hover{
    box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.39);
    cursor: pointer;
    transition: 0.3s;
  }
}

section{
  display: flex;
}
`


export default Dashboard



