import React, { useState } from "react";
import LoginForm from "./view/login.js";
import { getAuth, onAuthStateChanged} from "firebase/auth";
import Logout from "./component/logoutBtn.js";
import CreateUser from "./view/createUser.js";
import CreateCluster from "./view/createCluster.js";
import Dashboard from "./view/dashboard.js";
import ResetPassword from "./view/resetPassword.js";
import Profile from "./view/profile.js";
import ChangeEmail from "./settings/changeEmail.js";
import ChangePassword from "./settings/changePassword.js";
import Settings from "./settings/settings.js";
import styled from "styled-components";
import LandingPage from "./view/landingPage.js";
import Checkout from "./view/checkout.jsx";
import './App.css';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from "react-router-dom";

import NavBar from "./component/navbar.js";
import auth from "./firebaseConfig.js";
function App() {
  onAuthStateChanged(auth, user => {
    if (user !== null) {
        console.log("There is a user", user);
    } else {
        console.log("No user");
    }
});



const location = useLocation();
const isCreateClusterPath = location.pathname === "/createCluster";



  return (
<StyledApp>
<div style={{ backgroundColor: isCreateClusterPath ? "#090909" : "#f5f5f5" }}>
  <div className="app-line-break"></div>
      {/* <NavBar/> */}
      <Routes>
        {/* <Route path="/" element={<LandingPage/>} />  */}
        <Route path="/" element={<LoginForm auth={auth} />} />
        <Route path="/createCluster" element={<CreateCluster auth={auth} />} />
        <Route path="/checkout" element={<Checkout/>} />
        <Route path="/logout" element={<Logout auth={auth} />} />
        <Route path="/dashboard" element={<Dashboard auth={auth} />} />
        <Route path="/registerUser" element={<CreateUser auth={auth} />} />
        <Route path="/resetPassword" element={<ResetPassword auth={auth} />} />
        <Route path="/profile" element={<Profile auth={auth} />} />
        <Route path="/changeEmail" element={<ChangeEmail auth={auth} />} />
        <Route path="/changePassword" element={<ChangePassword auth={auth} />} />
      </Routes>
    </div>
    </StyledApp >
  );
}
const StyledApp = styled.main`
.app-line-break{
 height: 5rem;
}
`
export default App;

// import React, { useState, useEffect } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./view/CheckoutForm.jsx";
// import "./App.css";

// const stripePromise = loadStripe("pk_test_4QHSdRjQiwkzokPPCiK33eOq");

// export default function App() {
//   const [clientSecret, setClientSecret] = useState("");

//   useEffect(() => {
//     // Create PaymentIntent as soon as the page loads
//     fetch("http://localhost:4242/create-payment-intent", {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
//     })
//       .then((res) => res.json())
//       .then((data) => setClientSecret(data.clientSecret))
//       .catch((error) => console.log(error));
//   }, []);

//   const appearance = {
//     theme: 'stripe',
//   };
//   const options = {
//     clientSecret,
//     appearance,
//   };

//   return (
//     <div className="App">
//       {clientSecret && (
//         <Elements options={options} stripe={stripePromise}>
//           <CheckoutForm />
//         </Elements>
//       )}
//     </div>
//   );
// }
