import axios from 'axios'

let API_BASE_URL = process.env.REACT_APP_GIT_URL

const service = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Accept': 'application/vnd.github+json',
    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
    'Content-Type': 'application/json'
  },
  timeout: 30000,
})


class DataAPI {
    constructor() {
        this.instance = service
    }

    // 
    async postCluster(provider,region, size, clusterName, selectedVersion, userId) {
      console.log("workflow triggered fomr axios", provider,region, size, selectedVersion, userId)
      return this.instance.post(`${provider}.yaml/dispatches`, {
        ref: "main",
        inputs: {
          "region": region,
          "size": size,
          "cluster_name": clusterName,
          "name": userId,
          "cluster_version": selectedVersion
        }
      });  
  }

  async deleteCluster(provider,region, size) {
    console.log("workflow triggered fomr axios", provider,region, size)
    return this.instance.post(`delete_${provider}.yaml/dispatches`, {
      ref: "main",
      inputs: {
        "region": region,
      }
    });  
}


}

const dataApi = new DataAPI()

export { dataApi };