import React, { useState } from 'react';
import styled from 'styled-components';
import RegionButton from '../component/regionButton';
import SizeButton from '../component/sizeButton';
import GermanyFlag from '../asset/germany-flag.svg'
import UkFlag from '../asset/uk-flag.svg'
import FinlandFlag from '../asset/finland-flag.svg'
import LargeSize from '../asset/large-size.svg'
import MediumSize from '../asset/medium-size.svg'
import SmallSize from '../asset/small-size.svg'

const GcpForm = (props) => {
  // const [region, setRegion] = useState('westeurope');
  // const [size, setSize] = useState('standard_D2s_v3');
    const [region, setRegion] = useState('europe-north1');
  const [size, setSize] = useState('e2-standard-4');

  const handleGcpRegionChange = (selectedRegion) => {
    setRegion(selectedRegion);
    props.onGcpRegionChange(selectedRegion);
  };

  const handleGcpSizeChange = (selectedSize) => {
    setSize(selectedSize);
    props.onGcpSizeChange(selectedSize);
  };

  return (
    <StyledAzureForm>
      <h3>Choose Region</h3>
      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of </p>
      <form>

          <RegionButton
            region="europe-north1"
            flag={FinlandFlag}
            selected={region === 'europe-north1'}
            country="Hamina"
            onClick={() => handleGcpRegionChange('europe-north1')}
          />
          <RegionButton
            region="europe-west2"
            flag={UkFlag}
            selected={region === 'europe-west2'}
            country="London"
            onClick={() => handleGcpRegionChange('europe-west2')}
          />
          <RegionButton
            region="europe-west3"
            flag={GermanyFlag}
            selected={region === 'europe-west3'}
            country="Frankfurt"
            onClick={() => handleGcpRegionChange('europe-west3')}
          />

      </form>

      <h3>Select Size</h3>
      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of </p>
      <form>

          <SizeButton
            size="e2-medium"
            sizeIcon={SmallSize}
            selected={size === 'e2-medium'}
            monthlyCost="€10/month"
            hourlyCost="€0.004/hour"
            onClick={() => handleGcpSizeChange('e2-medium')}
          />
          <SizeButton
            size="e2-standard-4"
            sizeIcon={MediumSize}
            selected={size === 'e2-standard-4'}
            monthlyCost="€10/month"
            hourlyCost="€0.004/hour"
            onClick={() => handleGcpSizeChange('e2-standard-4')}
          />
          <SizeButton
            size="e2-standard-8"
            sizeIcon={LargeSize}
            selected={size === 'e2-standard-8'}
            monthlyCost="€10/month"
            hourlyCost="€0.004/hour"
            onClick={() => handleGcpSizeChange('e2-standard-8')}
          />

      </form>
    </StyledAzureForm>
  );
};

const StyledAzureForm = styled.div`
  form {
    display: flex;
    justify-content: space-between;
  }

  form div:nth-child(2){
  /* background-color: red; */
  margin-left: 1rem;
  margin-right: 1rem;
  box-sizing: border-box;
}

h3{
  margin-top: 4rem;
}

@media only screen and (max-width: 900px) {
  form div:nth-child(2){
  /* background-color: red; */
  margin-left: 0.5rem;
  margin-right: 0.5rem;

}
}

`;

export default GcpForm;

