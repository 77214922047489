import styled from "styled-components";


const RegionButton = ({ region, selected, onClick, country, flag }) => {
    return (
      <StyledOption
        className={`option ${selected ? 'selected' : ''}`}
        onClick={onClick}
      >
        <div className="top-wrap">
        {/* <div className="img-div" ></div> */
        <div className="img-div" style={{ backgroundImage: `url(${flag})` }}></div>}
        {region}
        </div>
        <div className="line-break"></div>

        {country}
    
        
      </StyledOption>
    );
  };
  
  const StyledOption = styled.div`
  background-color: white;
  margin-top: 2rem;
  cursor: pointer;

  min-width: 18vmin;
  width: 24vmin;
  min-height: 15.25vmin;
  /* border: 1px solid black; */
  border-radius: 10px;
  display: flex;
  align-items: center;

  box-sizing: border-box;
  flex-direction: column;

  
  :hover{
    transition: 0.3s;
    box-shadow: 0px 0px 10px 0px rgba(255,255,255,0.75);

  }
  .top-wrap{
    padding: 1rem 1rem 0 1rem;
    box-sizing:border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
  }



  .img-div{
    margin-right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }



  .line-break{
    background-color: black;
    width: 100%;
    height: 1px;
    margin-bottom: 1rem;
  }
    &.selected {
      position: relative;
      border: 1px solid #00FF38;
      /* background-color: #D9D9D9; */
      caret-color: transparent;
      box-sizing: border-box;
      overflow: hidden;
      &:after{
        content: "";
        top: -1.5rem;
        right: -1.5rem;
        border-top-right-radius: 10px;
        rotate: 50deg;
        position: absolute;
        width: 45px;
        height: 45px;
        background-color: #00FF38;
        
      }
    }

    @media only screen and (max-width: 900px) {
    padding-bottom: 0.5rem;
    .top-wrap{
    flex-direction: column;
    font-size: 2.5vw;
  }
  .line-break{
    margin-bottom: 0.5rem;
  }
  .img-div{
    margin-right: 0rem;
  }
}

@media only screen and (max-width: 670px) {
    .top-wrap{
    font-size: 3vw;
  }
}
@media only screen and (max-width: 420px) {
    .top-wrap{
    font-size: 3.5vw;
  }
}
  `;


  export default RegionButton

