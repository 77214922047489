import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import Logout from '../component/logoutBtn';
import axios from 'axios';
import CreateUser from './createUser';
import GoogleLogin from '../component/googleLogin';
import { dataApi } from '../api/api';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NavBar from '../component/navbar';
import GoogleIcon from '../asset/google-icon.svg'

const Login = ({auth}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [creationTime, setCreationTime] = useState(null);

  const navigate = useNavigate();

  console.log(auth, "This is auth console.log")
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password)
      console.log("User loggedin successfully", auth);
      console.log("Login successful: ", email);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        console.log('User is already signed in:', user.email);
        if (!user.metadata.creationTime) {
          console.log('Could not retrieve creation time');
        } else {
          const currentTime = Date.now();
          const thirtySeconds = 30 * 1000; // 30 seconds in milliseconds
          const userCreationTime = Date.parse(user.metadata.creationTime);
          const timeSinceCreation = currentTime - userCreationTime;

          console.log('User creation time:', user.metadata.creationTime);
          console.log('Time since creation:', timeSinceCreation);

          if (timeSinceCreation <= thirtySeconds) {
            navigate('/createCluster');
          } else {
            navigate('/dashboard');
          }
        }
      } else {
        console.log('User is not signed in');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <StyledLogin>
      {/* <NavBar/> */}
      <h3>Login</h3>
    <form onSubmit={handleLogin}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <div>
      <Link to='resetPassword'>Forgot password?</Link>
      </div>
            
      <button type="submit">Login</button>
      
    </form>
        <div>

      {/* <p>or</p> */}
      {/* <div className='google-button'> */}
        {/* <div></div> */}
      <GoogleLogin auth={auth}/>
      {/* </div> */}
   


      {/* {isLoginFormOpen && <LoginForm auth={auth} onClose={() => setLoginFormOpen(false)} />} */}

    </div>
    <p className='signup-text'>Or Sign Up Using</p>
    <Link className='sign-up' to='registerUser'>Sign Up</Link>
    </StyledLogin>
  );
};

const StyledLogin = styled.aside`
margin: auto;
width: 50%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;

a{
  text-decoration: none;
  color: black;
}

.sign-up{
  font-size: 1.3rem;
  font-weight: 500;
}

p{
  margin-top: 0;
}

.signup-text{
  margin-bottom: 0.5rem;
}
form div{
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;
}

form div a{
  display: flex;
  align-items: flex-start;
}

h3{
  margin-top: 7rem;
}

form{
  display: flex;
  flex-direction: column;
}
input{
  border-radius: 5px;
  padding-left: 2rem;
  margin-top: 1.5rem;
  width: 27rem;
  height: 2.5rem;
  /* border: 1px solid black; */
  border: 1px solid rgba(0, 0, 0, .1);
  outline: none;

}
form button, .google-button{
  border-radius: 25px;
  padding-left: 2rem;
  margin-bottom: 1.5rem;
  width: 29.5rem;
  height: 2.5rem;
  font-size: 1.3rem;
  color: white;
  background: rgb(254,51,255);
  background: linear-gradient(254deg, rgba(254,51,255,1) 6%, rgba(172,171,253,1) 50%, rgba(104,255,250,1) 81%);
  border: none;
  transition: all 0.3s ease-out;
  &:hover{
    scale: 1.05;
    cursor: pointer;
  }
}
.google-button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

section{
  display: flex;
}
`
export default Login;