import styled from "styled-components";
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Settings from "../settings/settings";

const NavBar = ({ auth }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const isCreateClusterPath = location.pathname === "/createCluster";

  const handleMenuClick = () => setShowMenu(!showMenu);
  const handleSettingsClick = () => setShowSettings(!showSettings);
  const handleCreateClusterClick = () => navigate("/createCluster");
  const handleDashboardClick = () => navigate("/dashboard");
  const handleLandingPageClick = () => navigate("/");

  // const wrapperRef = useRef(null);

  // function useHideOutsideSetting(ref){
  //   useEffect(() => {
  //     function handleClickOutside(event) {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //       //   alert("You clicked outside of me!");
  //         setShowSettings(false);
  //       }
  //     }
  //     // Bind the event listener
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       // Unbind the event listener on clean up
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [ref]);
  // }

  // useHideOutsideSetting(wrapperRef);

  const btnRef = useRef()

  useEffect(() => {
    const closeSettings = (e) => {
      if (
        showSettings &&
        !btnRef.current.contains(e.target) &&
        !document.getElementById("settings").contains(e.target)
      ) {
        setShowSettings(false);
      }
    };
  
    document.body.addEventListener("click", closeSettings);
  
    return () => {
      document.body.removeEventListener("click", closeSettings);
    };
  }, [showSettings]);

  return (
    <>
    <StyledOption clusterPath={isCreateClusterPath} showMenu={showMenu}>
      <div className="logo" onClick={handleLandingPageClick}></div>
      <div className={`cover ${showSettings ? 'show-cover' : ''}`}></div>
      <aside onClick={handleMenuClick} className={`${showMenu ? 'show' : ''}`}></aside>
      <ul className={`${isCreateClusterPath ? 'black-background' : 'white-background'} setting-ul ${showMenu ? 'show' : ''}`}>
        <li className="main-nav">
          <button aria-label="Dashboard navigation" onClick={handleDashboardClick}>Dashboard</button>
        </li>
        <li  className="main-nav">
          <button aria-label="Create-cluster navigation" onClick={handleCreateClusterClick}>Create cluster</button>
        </li>
        <li  className="main-nav">
          <button aria-label="Settings dropdown for additional links" ref={btnRef} onClick={()=> setShowSettings(prev => !prev)}>Settings</button>
        </li>
        <li>
        {showSettings && <Settings/>}
        </li>
      </ul>
      {/* {showSettings && <Settings/>} */}

    </StyledOption>
          <Button aria-label="Navigation dropdown"  onClick={handleMenuClick} showMenu={showMenu} isCreateClusterPath={isCreateClusterPath}>
          <div />
          <div />
          <div />
        </Button>
  </>
  );
};

const StyledOption = styled.header`

  top: 0;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  /* background-color: transparent; */
  background-color: ${props => props.clusterPath ? "black" : "#f5f5f5" };
  padding-top: 0.5rem;
  margin-bottom: 1rem;
  z-index: 5;
  margin: auto;

  .logo {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: gray;
  }

  .setting-ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    position: relative;
  }

  button {
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    margin-right: 1rem;
    font-weight: normal;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .black-background > li button {
    color: white;
    /* color: ${props => props.showMenu ? 'black' : ''}; */
  }
  .white-background li button {
    color: black;
  }

  @media (max-width: 950px) {
 /* display: none; */
    top: 0;
  /* width: 100%; */
  position: fixed;
  display: flex;
  /* justify-content: flex-start; */
  position: fixed;
  z-index: 5;
    padding-top: 0rem;
    .logo{
      display: none;
    }
    .setting-ul {
     display: none;
      position: absolute;
      top: 0;
      padding: 1rem 0 1rem 1rem;
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      transition: transform 0.3s ease-in-out;
      z-index: 1;

            &.show {
              display: block;
                top: 0;
                margin: 0;
                left:0;
                bottom: 0;
                padding-top: 5rem;
                background-color: white;
                width: 15rem;
                height: 100vh;
                transition: 0.3s;
                z-index: 3;
            }
        }
        aside{
            position: fixed;
            background-color: transparent;
            transition: transform 0.3s ease-in-out;
            z-index: 1;
            &.show {
                width: 100%;
                height: 100vh;
                transition: 0.3s;
                backdrop-filter: blur(1.5px);
            }
        }
        ul li button{
        font-size: 0.875rem;
        margin-right: 0;
    }

    ul li{
      display: flex;
    }
    ul li.main-nav{
      /* background-color: orange; */
      height: 2rem;
    }
    .black-background > li button {
    color: black;
    /* color: ${props => props.showMenu ? 'black' : ''}; */
  }
    }

`;


const Button = styled.button`
  display: none;

  @media (max-width: 950px) {
    
    position: fixed;
    top: 1rem;
    left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
   
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
      outline: none;
    }

    div {
      width: 1.5rem;
      height: 0.2rem;
      background: black;
      /* background-color: ${props => props.showMenu ? '#000' : '#fff'}; */
      background-color: ${props => props.isCreateClusterPath ? 'white' : 'black'};
      background-color: ${props => props.showMenu ? 'black' : ''};
      border-radius: 10px;
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
    }

    div:first-child {
      transform: ${({ showMenu }) => (showMenu ? 'rotate(45deg)' : 'rotate(0)')};
    }

    div:nth-child(2) {
      opacity: ${({ showMenu }) => (showMenu ? '0' : '1')};
      transform: ${({ showMenu }) => (showMenu ? 'translateX(20px)' : 'translateX(0)')};
    }

    div:last-child {
      transform: ${({ showMenu }) => (showMenu ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export default NavBar;