import React from 'react';
import { signInWithPopup, TwitterAuthProvider, FacebookAuthProvider, GoogleAuthProvider  } from "firebase/auth";
import styled from "styled-components";
import GoogleIcon from '../asset/google-icon.svg'
import TwitterIcon from '../asset/twitter-icon.svg';
import FacebookIcon from '../asset/facebook-icon.svg';

const GoogleLogin = ({auth}) => {
 

    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();
    const twitterProvider = new TwitterAuthProvider();

    const signInWithGoogle = async () => {
      try {
        await signInWithPopup(auth,googleProvider);
      } catch (error) {
        console.error(error);
      }
    };

    const signInWithFacebook = async () => {
      try {
        await signInWithPopup(auth,facebookProvider);
      } catch (error) {
        console.error(error);
      }
    };

    const signInWithTwitter = async () => {
      try {
        await signInWithPopup(auth,twitterProvider);
      } catch (error) {
        console.error(error);
      }
    };
  return (
    <StyledLogin>
      <p>Sign Up Using</p>
      <div>
      <button aria-label="Sign In with Google" className='button google' onClick={signInWithGoogle}></button>
      <button aria-label="Sign In with Twitter" className='button twitter' onClick={signInWithTwitter}></button>
      <button aria-label="Sign In with Facebook" className='button facebook' onClick={signInWithFacebook}></button>
      </div>
         
    </StyledLogin>
  );
};

const StyledLogin = styled.aside`
margin: 3rem 0 7rem 0;
.button{
  background-color: white;
border:none;
border-radius: 50%;
margin: 0 0.5rem;
height: 3rem;
width: 3rem;
background-repeat: no-repeat;
background-size: contain;
background-position: center;
&:hover{
cursor: pointer;
}
}

.google{
  background-image: url(${GoogleIcon});
}
.twitter{
  background-image: url(${TwitterIcon});
}
.facebook{
  background-image: url(${FacebookIcon});
}
`
export default GoogleLogin;
