import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { dataApi } from '../api/api';
import { useNavigate } from 'react-router-dom';
import auth from '../firebaseConfig';



const PurchaseList = (props) => {


    const navigate = useNavigate();

    const [userId, setUserId] = useState('');

    

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (user) {
          setUserId(user.uid);
        } else {
          setUserId(null);
        }
      });
      return () => unsubscribe();
    }, []);


console.log("THIS IS THE PRORPS", props.provider, props.region, props.size, props.clusterName, props.selectedVersion, userId )

// const {provider, region, size} = props
console.log("This is props", props.provider)
  const handleSubmitAxios = async (e) => {
    e.preventDefault();
    var response = await dataApi.postCluster(props.provider,props.region,props.size, props.clusterName, props.selectedVersion, userId);
    if (response.status !== 204) {
      throw new Error(response.data);
    }
    // navigate("/checkout", { state: { provider: props.provider, region: props.region, size: props.size, } })

  };

  return (
    <StyledAzureForm>
      {/* <h1>Checkout</h1> */}
            {/* <div className='item-container'>
        <h4>Cluster Name</h4>
        <div className='cluster-name'>
          <p>North american temp save</p>
        </div>
      </div> */}
            <div className='item-container'>
        <h4>Cluster</h4>
        <div>
          <h4>Cluster Name</h4>
          <p>{props.clusterName}</p>
        </div>
      </div>

      <div className='item-container'>
        <h4>Version</h4>
        <div>
          <h4>Version</h4>
          <p>{props.selectedVersion}</p>
        </div>
      </div>
      <div className='item-container'>
        <h4>Provider</h4>
        <div>
        <h4>Provider</h4>
          <p>{props.provider}</p>
        </div>
      </div>
      <div className='item-container'>
        <h4>Region</h4>
        <div>
        <h4>Region</h4>
          <p>{props.region}</p>
        </div>
      </div>
      <div className='item-container'>
        <h4>Size</h4>
        <div>
        <h4>Size</h4>
          <p>{props.size}</p>
        </div>
      </div>
      <div className='item-container '>
        <h4>Total cost</h4>
        <div>
        <h4>Total cost</h4>
          <p>$1.1/hour</p>
        </div>
      </div>
   <button onClick={handleSubmitAxios}>Create</button>
   {/* <button onClick={() => props.choosePage(settingNewPage)}>New page</button> */}
    </StyledAzureForm>
  );
};

const StyledAzureForm = styled.section`
margin: 4rem 0 2rem 0;
    height: 8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid black;
    text-align: center;
    background-color: white;
    border-radius: 20px;
    h4{
      font-size: 1rem;
      margin: 0;
    }

.item-container h4, .cost-container h4{
margin-bottom: 0.5rem;
}



.item-container div{
  width: 100%;
  min-height: 2.5rem;
  border-radius: 10px;
  background-color: rgba(217,217,217,25% );
  /* overflow: scroll; */
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  p{
    color: black;
  }

  h4{
    display: none;
  }
}

.item-container .cluster-name{
    height: 4rem;
  }


button{
  cursor: pointer;
  margin-top: 1.5rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 20px;
  min-width: 6rem;
  width: 9rem;
  height: 3.5rem;
  background-color: #00FF38;
  color: black;
  &:hover{
    transition: 0.3s;
    scale: 1.01;
    box-shadow: 0px 0px 15px 0px rgba(124,252,0,0.75);
  }
}
.item-container{
/* background-color: red; */
min-width: 5.5rem;
width: 7rem;

}
@media only screen and (max-width: 950px) {
  /* width: 100%; */
flex-direction: column;
height: auto;
padding: 1rem;
  .item-container{
    width: 100%;
    margin-bottom: 0.5rem;
    h4{
      display: none;
    }
    div{
      border-radius: 5px;
    background-color: rgba(217,217,217,50% );
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    box-sizing: border-box;
      h4{
        display: block;
      }
    }
  }


  button{
margin: 0;
  border-radius: 10px;
  width: 100%;
  height: 3rem;
  background-color: #00FF38;
  color: black;
  &:hover{
    transition: 0.3s;
    scale: 1.01;
    box-shadow: 0px 0px 5px 0px rgba(124,252,0,0.75);
  }
}

}
`;

export default PurchaseList;

