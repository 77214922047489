
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm.jsx";

const stripePromise = loadStripe("pk_test_4QHSdRjQiwkzokPPCiK33eOq");

export default function App() {
  const [clientSecret, setClientSecret] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("http://localhost:4242/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ minutes: 44 }),
    })
      .then((res) => res.json())
      .then((data) => {
        setTotalAmount(data.totalAmount);
        setClientSecret(data.clientSecret);
      })
      .catch((error) => console.log(error));
  }, []);

  console.log("This is secret", clientSecret)
  console.log("This is totalcost frontend", totalAmount)
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm totalAmount={totalAmount} />
        </Elements>
      )}
    </div>
  );
}

