import React, { useState } from 'react';
import { reauthenticateWithCredential, updateEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import auth from "../firebaseConfig.js";
import styled from "styled-components";
const ChangeEmail = () => {

  const navigate = useNavigate();



  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log("User logged out");
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <StyledOption onClick={handleLogout}>Logout</StyledOption>
    </>
  );
};



const StyledOption = styled.button`
  margin-right: 0 !important;
  &:hover{
    cursor: pointer;
  }
`;

export default ChangeEmail;
