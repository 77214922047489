import React, { useState } from 'react';
import styled from 'styled-components';
import RegionButton from '../component/regionButton';
import SizeButton from '../component/sizeButton';
import SwedishFlag from '../asset/sweden-flag.svg'
import UkFlag from '../asset/uk-flag.svg'
import NetherlandsFlag from '../asset/netherlands-flag.svg'
import LargeSize from '../asset/large-size.svg'
import MediumSize from '../asset/medium-size.svg'
import SmallSize from '../asset/small-size.svg'

const AzureForm = (props) => {
  const [region, setRegion] = useState('westeurope');
  const [size, setSize] = useState('standard_D2s_v3');

  const handleAzureRegionChange = (selectedRegion) => {
    setRegion(selectedRegion);
    props.onAzureRegionChange(selectedRegion);
  };

  const handleAzureSizeChange = (selectedSize) => {
    setSize(selectedSize);
    props.onAzureSizeChange(selectedSize);
  };

  return (
    <StyledAzureForm>
        <h3>Choose Region</h3>
  <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of </p>
      <form>
          <RegionButton
            region="swedencentral"
            flag={SwedishFlag}
            selected={region === 'swedencentral'}
            country="Stockholm"
            onClick={() => handleAzureRegionChange('swedencentral')}
          />
          <RegionButton
            region="westeurope"
            flag={NetherlandsFlag}
            selected={region === 'westeurope'}
            country="Amsterdam"
            onClick={() => handleAzureRegionChange('westeurope')}
          />
          <RegionButton
            region="uksouth"
            flag={UkFlag}
            selected={region === 'uksouth'}
            country="London"
            onClick={() => handleAzureRegionChange('uksouth')}
          />

      </form>

      <h3>Select Size</h3>
      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of </p>
      <form>
          <SizeButton
            size="standard_D2s_v3"
            sizeIcon={SmallSize}
            selected={size === 'standard_D2s_v3'}
            monthlyCost="€10/month"
            hourlyCost="€0.004/hour"
            onClick={() => handleAzureSizeChange('standard_D2s_v3')}
          />
          <SizeButton
            size="standard_B2s"
            sizeIcon={MediumSize}
            selected={size === 'standard_B2s'}
            monthlyCost="€10/month"
            hourlyCost="€0.004/hour"
            onClick={() => handleAzureSizeChange('standard_B2s')}
          />
          <SizeButton
            size="standard_B4ms"
            sizeIcon={LargeSize}
            selected={size === 'standard_B4ms'}
            monthlyCost="€10/month"
            hourlyCost="€0.004/hour"
            onClick={() => handleAzureSizeChange('standard_B4ms')}
          />
      </form>
    </StyledAzureForm>
  );
};

const StyledAzureForm = styled.div`
  form {
     display: flex;
     flex-direction: row;
     justify-content: space-between;

  }

  form div:nth-child(2){
  /* background-color: red; */
  margin-left: 1rem;
  margin-right: 1rem;
  box-sizing: border-box;
}
  h3{
  margin-top: 4rem;
}
@media only screen and (max-width: 900px) {
  form div:nth-child(2){
  /* background-color: red; */
  margin-left: 0.5rem;
  margin-right: 0.5rem;

}
}
`;

export default AzureForm;